// body {
//   background-color: red !important;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
// }

// //GLOBAL
// .title {
//   line-height: 34px !important;
//   font-weight: 700 !important;
//   &-dot {
//     position: relative;
//     display: inline-block;
//     margin-left: 0.75rem;
//     &::before {
//       content: "";
//       width: 10px;
//       height: 10px;
//       bottom: 0;
//       background-color: #2267c9;
//       position: absolute;
//     }
//   }
// }

// .custom-text {
//   margin-right: 1rem;
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

// /* Chrome, Safari, Edge, Opera */
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mapboxgl-popup {
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  color: #333333;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.mapboxgl-popup-content {
  padding: 10px;
  background-color: #ffffffb8;
  border-color: #bf0000;
  border-width: 1px;
  border-style: solid;
}

.mapboxgl-popup-close-button:focus-visible {
  border: none;
  outline: none;
}
.mapboxgl-popup-tip {
  border-top-color: #ffffff; /* Uç kısmın rengini ayarlayın */
}

p {
  margin: 0;
}