$background-color_1: red;
$border-color_1: #bf0000;
$border-color_2: #212121;

$background-color_1_dark: white;
$border-color_1_dark: white;
$border-color_2_dark: white;

/* input focus stilini özelleştirme */
/* input hover stilini özelleştirme */
/* buton focus stilini özelleştirme */
/* buton hover stilini özelleştirme */
.react-tel-input {
  &.dark-mode {
    .form-control {
      &:focus {
        border-color: $border-color_1_dark !important;
        outline: 2px;
        box-shadow: unset;
      }
      &:hover {
        border-color: $border-color_2_dark !important;
      }
      &:focus-within {
        border-color: $border-color_1_dark !important;
      }
    }
    .special-label {
      background-color: #1e1e1e;
    }
    .country-list {
      .country {
        &:hover {
          background-color: #525252 !important;
        }
        &.highlight {
          background-color: #525252 !important;
        }
      }
    }
  }
  &.light-mode {
    .form-control {
      &:focus {
        border-color: $border-color_1 !important;
        border-width: 2px;
        box-shadow: unset;
      }
      &:hover {
        border-color: $border-color_2 !important;
      }
      &:focus-within {
        border-color: $border-color_1 !important;
      }
    }
    .special-label {
      background-color: #ffffff;
    }
    .country-list {
      .country {
        &:hover {
          background-color: #efefef !important;
        }
        &.highlight {
          background-color: #efefef !important;
        }
      }
    }
  }
}
// .react-tel-input {
//   &.dark-mode {
//     .country-list {
//       .country {
//         &:hover {
//           background-color: $background-color_1 !important;
//           background-color: $background-color_1 !important;
//         }
//         &.highlight {
//           background-color: $background-color_1 !important;
//           background-color: $background-color_1 !important;
//         }
//       }
//     }
//   }
//   &.light-mode {
//     .country-list {
//       .country {
//         &:hover {
//           background-color: $background-color_1_dark !important;
//           background-color: $background-color_1_dark !important;
//         }
//         &.highlight {
//           background-color: $background-color_1_dark !important;
//           background-color: $background-color_1_dark !important;
//         }
//       }
//     }
//   }
// }
